@import "../media/lib";

$grid-breakpoints: map-merge(("xxs": 0px), $breakpoints);
$grid-gutter-width: 30px;

$container-max-widths: ();

@each $key, $breakpoint in $breakpoints {
  @if $breakpoint > 568px {
    $container-max-widths: map-merge($container-max-widths, ($key: $breakpoint - $grid-gutter-width));
  }
}

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
//@import "node_modules/bootstrap/scss/type";
//@import "node_modules/bootstrap/scss/images";
//@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
//@import "node_modules/bootstrap/scss/tables";
//@import "node_modules/bootstrap/scss/forms";
//@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
//@import "node_modules/bootstrap/scss/dropdown";
//@import "node_modules/bootstrap/scss/button-group";
//@import "node_modules/bootstrap/scss/input-group";
//@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
//@import "node_modules/bootstrap/scss/navbar";
//@import "node_modules/bootstrap/scss/card";
//@import "node_modules/bootstrap/scss/breadcrumb";
//@import "node_modules/bootstrap/scss/pagination";
//@import "node_modules/bootstrap/scss/badge";
//@import "node_modules/bootstrap/scss/jumbotron";
//@import "node_modules/bootstrap/scss/alert";
//@import "node_modules/bootstrap/scss/progress";
//@import "node_modules/bootstrap/scss/media";
//@import "node_modules/bootstrap/scss/list-group";
//@import "node_modules/bootstrap/scss/close";
//@import "node_modules/bootstrap/scss/modal";
//@import "node_modules/bootstrap/scss/tooltip";
//@import "node_modules/bootstrap/scss/popover";
//@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";


.container {
  @include breakpoints {
    max-width: $bp - $grid-gutter-width;
  }

  @media(max-width: $xs-max) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.wide-gutters {
  margin-right: -1 * $grid-gutter-width;
  margin-left: -1 * $grid-gutter-width;

  > .col,
  > [class*="col-"] {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }
}

.column-margin {
  margin-top: $grid-gutter-width / 2;
  margin-bottom: $grid-gutter-width / 2;
}
